import React from 'react'
import {Container , Card ,Col , Row} from 'react-bootstrap'

const Resume = () => {
  return (
    <>
      <Container className='mt-4'>
        <Card>
            <Card.Header className='text-center h3'>Resume</Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                    <div className='resume-text'>
                        <h4 className='text-center mb-4'>Professional experience</h4>
                        <ul>
                            <li>
                                <h5>Web developer </h5>
                                <h5>  <small className='text-muted'>oct 2019 - apr 2021</small> </h5>
                                <p>- october 2019 i started learning JAVA , OOP and basic algorithms.</p>
                                <p>- april 2021 the attention was directed to Web Development carrying 
                                    out multiple personal projects in which i used (HTML , Vanila JS , CSS,
                                     React JS, PostgreSQL , Node JS, NPM, Express JS, JSON, GIT, Postman).
                                </p>
                                <p>
                                    - november 2021 added to portofolio two more websites and got new skills.
                                    I worked with Embedded JS ,MongoDB, mongoose, flash, Schemas,
                                     Joi(schema validation), express-session, html-sanitize,
                                      passport (password management), helmet(headers security), Maps(Mapbox), Geocoding.
                                    
                                </p>
                            </li>
                            <li>
                                <h5>Associate - Panconeli.SRL </h5>
                                <h5>  <small className='text-muted'>Apr 2014 - present</small> </h5>
                                <p>-What i do - accounting, supply of goods, purchases for the proper functioning of the store,
                                     employee training, employee supervision, managing related field documentation
                                      for authorities (ITM, DSV , DSP , etc.)
                                </p>
                            </li>
                            <li>
                                <h5>Data specilist - Vodafone Romania </h5>
                                <h5><small className='text-muted'>Dec 2012 - Mar 2014</small></h5>
                                <p>
                                   - Establish positive long-term relationships with customers by learning
                                    about their business,listening to and/or identifying their needs,
                                     promoting appropriate products. 
                                </p>
                                <p>
                                    - Focus on growing existing clients.
                                </p>
                                <p>
                                    - Arrange meetings with clients, preparing necessary documents for signing
                                     and provisioning all data needed in our systems.
                                </p>
                                <p>
                                    - Offering technical support for our applications that my clients use.
                                </p>
                            </li>
                            <li>
                                <h5>Call Center Agent - Cosmote RMT</h5>
                                <h5><small className='text-muted'>Oct 2008 - Nov 2012</small></h5>
                                <p>Solve different situations that clients have,on the phone such as network,
                                     data or billing problems.
                                </p>
                                <p>
                                    Promote and sell company services.
                                </p>
                            </li>
                        </ul>
                    </div>
                    </Col>
                    <Col style={{borderLeft:"solid black"}}>
                        <div className='resume-text'>
                            <h4 className='text-center mb-4'>Education</h4>
                             <ul>
                                <li>
                                    <h5>Self-taught Software Developer</h5>
                                    <h5> <small className='text-muted'>2019 - present</small> </h5>
                                    <p>Computer science | Remote</p>
                                </li>
                                <li>
                                    <h5>Bachelor's degree - Universitatea Spiru Haret </h5>
                                    <h5><small className='text-muted'>2011 - 2012</small> </h5>
                                    <p>Finance and Banking | Bucharest</p>
                                </li>
                                <li>
                                    <h5>Bachelor's degree - Academia de Studii Economice</h5>
                                    <h5> <small className='text-muted'>2008 - 2010</small> </h5>
                                    <p>Economics | Bucharest</p>
                                </li>
                                <li>
                                    <h5>High school - CN Al.I.Cuza </h5>
                                    <h5>  <small className='text-muted'> 2004 - 2008</small> </h5>
                                    <p> Mathematics and physics  | Focsani</p>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>

            </Card.Body>
            
        </Card>
      </Container>
    
    </>
  )
}

export default Resume