import Header from './components/Header';

import {Container} from 'react-bootstrap'
import './index.css'
import NavBar from './components/NavBar';
import ProjectCards from './components/ProjectCards'
import Footer from './components/Footer';
import Contact from './components/Contact'
import Resume from './components/Resume';
import Skills from './components/Skills';

function App() {
  return (
    <>
      <NavBar className="sticky-top" ></NavBar>
      <main>
        <Container   id='app-container'>
          <Header /> 

          <ProjectCards />

          <Skills/>

          <Resume />

          <Contact />

        </Container>
      </main>

        <Footer />
    </>
  );
}


export default App;
