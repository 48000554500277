import React from 'react'
import  {Row , Col , Container} from 'react-bootstrap'
import Card from './Card'

const ProjectCards = () => {
  return (
    <>
    <Container className='mb-4'>
        <Row>
            <Col xs={12} md={6}>
                <Card 
                  imageUrl="https://res.cloudinary.com/dggghnwn9/image/upload/w_600,h_400/v1646418783/LandingPage/YelpCampProject_thkalg.jpg" 
                  projectTitle="Yelp Camp"
                  projectIntro="EJS MVC project"
                  aboutProject=" Here you can view camp sites posted by users or post your own camp site.
                  After registering you can upload photos or review and grade other camp sites.
                  This is a small version of Yelp website and is hosted on heroku using a free tier
                  plan (not very fast)."
                  projectTechInfo="In this project i used the following tools and technologies: HTML, JavaScript, Embedded JS, CSS3, NPM, NodeJS,
                   Express, Postman, MongoDB,
                   mongoose, , flash , Schemas , Joi(schemas validation) , express-session , html-sanitize , passport (password management),
                   helmet(headers security)  , Maps(Mapbox) , Geocoding"
                  projectUrlRepo="#"
                  projectUrl="https://hidden-journey-58137.herokuapp.com/"
                />
            </Col>
            <Col xs={12} md={6}>
                <Card 
                  imageUrl='https://res.cloudinary.com/dggghnwn9/image/upload/w_600,h_400/v1647168625/LandingPage/Screenshot_1_tnprm2.jpg'
                  projectTitle="To do list"
                  projectIntro="React project"
                  aboutProject='This is a small web application, a version of To Do App.
                  This allows you to add a task, set a reminder on it or delete it. This app uses a 
                  a mock json server to store tasks'
                  projectTechInfo="In this project i used React, json-server, css3"
                  projectUrlRepo="https://github.com/Andrei1986-arch/react-task-tracker"
                />
            </Col>
            
        </Row>

    </Container>

    </>
  )
}

export default ProjectCards