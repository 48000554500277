
import PropTypes from 'prop-types'
import React, {useState} from 'react'


const Card = ({imageUrl , projectIntro , projectTitle , aboutProject , projectTechInfo , projectUrlRepo , projectUrl}) => {

    const [readMoreAbout , setReadMoreAbout] = useState(false)
    const [readMoreTech , setReadMoreTech] = useState(false)

    // cardProps ({imageUrl , projectIntro , projectTitle , aboutProject})

    const description = (str) => {
        if(str.length > 200){
            return `${str.substring(0 , 200)}...`
        }
    }

  return (
    <>
        <div className="card mb-4   h-100" >
            <h3 className="card-header">{projectTitle}</h3>
            
                <h5 className="card-title m-4">{projectIntro}</h5>
            
            <div className="card-body" style={{backgroundImage:`url(${imageUrl})`,backgroundSize:"cover",backgroundPosition:'top' , height:"16rem"}}>
                {/* <img style={{background}} src= { imageUrl } alt='' /> */}
            </div>
            
            <div className="card-body">
                <div className="card-text"> 

                    {readMoreAbout ? aboutProject : ((aboutProject.length > 200 && `${aboutProject.substring(0 , 200)}...`) || aboutProject)}
                    {/* {(aboutProject.length > 200  && `${aboutProject.substring(0 , 200)}...`) || aboutProject} */}
                    {aboutProject.length > 200 && 
                        <a className="card-link link-cursor-pointer" onClick={() => setReadMoreAbout(!readMoreAbout)} >
                            {readMoreAbout ? '--> show less' : '--> show more'}
                        </a>
                    }
                </div>
            </div>
            
            <div className="card-body">
                <h6>Technologies used</h6>
                <p>
                    {/* if readMoreTech is true display project info  else check if str length
                    is greater then 200 and display subs + (...)  */}
                    {readMoreTech ? projectTechInfo : ((projectTechInfo.length > 200 && `${projectTechInfo.substring(0 , 200)}...`) || projectTechInfo)}
                    {projectTechInfo.length > 200 && 
                        <a className="card-link link-cursor-pointer" onClick={() => setReadMoreTech(!readMoreTech)} >
                            {readMoreTech ? '--> show less': '--> show more'}
                        </a>
                    }
                </p>
            </div>
            <div className="card-body">
                {(projectUrl !== '#') &&
                <a target="_blank" href={projectUrl} rel="noreferrer" className="card-link">To web page</a>
                }

                {(projectUrlRepo !== '#') && 
                <a target="_blank" href={projectUrlRepo} rel="noreferrer" className="card-link me-auto">To git repo</a>
                }
            </div>
            <div className="card-footer text-muted">
                Proudly made.
            </div>
            <div className="card-footer" style={{backgroundColor: "#20374c" , minHeight:"1.5rem" , border:"0px"}}>
                
            </div>
            </div>
    </>
  )
}

Card.defaultProps = {
    imageUrl : "https://res.cloudinary.com/dggghnwn9/image/upload/w_600,h_600,c_fill/v1646485543/LandingPage/noImage_zqnjos.jpg",
    projectTitle:"Project Title" ,
    projectIntro: "Subtitle" ,
    aboutProject:"aboutProject",
    projectTechInfo:"projectTechInfo",
    projectUrl:"#",
    projectUrlRepo:"#"
}

Card.propTypes = {
    imageUrl: PropTypes.string ,
    projectTitle: PropTypes.string ,
    projectIntro: PropTypes.string,
    aboutProject:PropTypes.string,
    projectTechInfo:PropTypes.string,
    projectUrlRepo:PropTypes.string,
    projectUrl: PropTypes.string
}

export default Card