import React from 'react'
import {Container , Row , Col} from 'react-bootstrap'

const Footer = () => {
  return (
      <Container className="sticky-bottom">
          <Row>
              <Col className='text-center py-3'>
                Copywright &copy; Panaite Andrei           
              </Col>
          </Row>
      </Container>
  )
}

export default Footer