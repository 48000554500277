import React from 'react'
import {Container , Card , Row , Col} from 'react-bootstrap'
import MapLoc from './MapLoc'


const Contact = () => {
  return (
    <>
      <Container id='contact'>

        <Card>
          <Card.Header className='text-center'>Contact</Card.Header>
          <Card.Body>
              <Card.Title className='text-center'></Card.Title>
              <Row>
                <Col>
                    <MapLoc >
                    </MapLoc>
                </Col>
                <Col className=''>
                  <p className='mt-4'>Email: panaite.office@gmail.com</p>
                  <address>Address: Bucharest , Romania</address>
                  <p>Phone number: it will be provided by email.</p>
              
                </Col>
              </Row>
          </Card.Body>
          </Card>

      </Container>
    </>
  )
}

export default Contact