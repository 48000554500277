import React from 'react'
import {Card , Table , Container} from 'react-bootstrap'

const Skills = () => {
  return (
    <>
    
      <Container>
        <Card>
            <Card.Header className='text-center h3'>Skills</Card.Header>
            <Card.Body>
                <Table responsive>
                    <tbody>
                        <tr>
                            <td>JavaScript</td>
                            <td>HTML</td>
                            <td>Node JS</td>
                            <td>Mongoose</td>
                        </tr>
                        <tr>
                            <td>React JS</td>
                            <td>CSS3</td>
                            <td>Express JS</td>
                            <td>MVC</td>
                        </tr>
                        <tr>
                            <td>Node JS</td>
                            <td>Bootstrap</td>
                            <td>SQL</td>
                            <td>Basic algorithms</td>
                        </tr>
                        <tr>
                            <td>Embedded JS</td>
                            <td>React Bootstrap</td>
                            <td>MongoDB</td>
                            <td>Code Sanitation</td>
                        </tr>
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
      </Container>
    
    </>
  )
}

export default Skills