import React from 'react'
import {Container , Card } from 'react-bootstrap'

function Header() {
    return (
<>

    <Container id="header">

        <Card>
        <Card.Header className='text-center h3'>Welcome</Card.Header>
        <Card.Body className='header-card'>
            <Card.Text className='text-center h4'>I am Panaite Andrei  && i am a self-taught Software Developer.</Card.Text>
            <Card.Text className='text-center h5'>
            A <span className='text-oblique'>bit</span> about me.
            <br />
            Some say that i'm a technology enthusiast and curious by nature. Or that i am obsessed to know how things work.
                    I can tell you that i made my passion to learn new things and build new cool stuff.
            </Card.Text>
        </Card.Body>
        </Card>

            {/* <Row>
                <Col className="justify-content-md-center text-center" >
                    <div className="header-container">
                        <h2>Welcome!</h2>
                        <h3>I am Panaite Andrei</h3>
                        <h3> && i am a self-taught Software Engineer.</h3>
                        <br />
                        <h4>A <span className='text-oblique'>bit</span> about me</h4>
                        <h4>Some say that i'm a technology enthusiast and curious by nature. Or that i am obsessed to know how things work.
                                I can tell you that i made my passion to learn new things and build new cool stuff.</h4>       
                    </div>
                </Col>
            </Row> */}
            
    </Container>
</>
    )
}

export default Header
